/* eslint-disable @next/next/no-img-element */
import AdsHalfStar from "assets/svgs/AdsHalfStar";
import ApnaLogo from "assets/svgs/ApnaLogoV2";
import CrossIcon16 from "assets/svgs/CrossIcon16";
import StarIcon from "modules/apnaLearn/assets/StarIcon";
import Image from "next/image";
import { useState } from "react";
import { getUrlPath } from "utils/helpers";
import Mixpanel from "utils/Mixpanel";

const WebToAppNudge = () => {
	const [isOpen, setIsOpen] = useState(true);

	const handleDownloadClick = () => {
		Mixpanel.track("Download app click", {
			page: getUrlPath(),
			Section: "Floating Nudge",
			pageType: "download apna app",
		});

		window.open(
			"https://play.google.com/store/apps/details?id=com.apnatime&referrer=utm_source%3DApna_main_website%26utm_medium%3DDownload_app_button_mWeb%26utm_campaign%3DFloating_nudge"
		);
	};
	if (isOpen) {
		return (
			<>
				<div className="fixed bottom-0 left-0 z-20 flex w-full flex-col space-y-[12px] rounded-t-[12px] border border-solid border-[#DFE1E6] bg-[#F1EAFA] p-[12px] md:hidden">
					<div className="flex h-max w-full items-center justify-between space-x-[8px]">
						<div className="flex items-center space-x-[8px]">
							<ApnaLogo size="40" />
							<div className="space-y-[2px]">
								<div className="flex items-center space-x-[4px]">
									<p className="m-0 text-[14px] font-semibold leading-[20px] text-[#172B4D]">
										4.6
									</p>
									<AdsHalfStar />
								</div>
								<p className="m-0 w-full text-nowrap text-[10px] leading-[12px] text-[#42526E]">
									655K reviews
								</p>
							</div>
							<div className="h-[34px] min-h-full w-[1px] bg-[#E8E7EA]" />
							<div className="space-y-[2px]">
								<div className="flex items-center space-x-[4px]">
									<p className="m-0 text-[14px] font-semibold leading-[20px] text-[#172B4D]">
										50M+
									</p>
								</div>
								<p className="m-0 w-full text-nowrap text-[10px] leading-[12px] text-[#42526E]">
									Downloads
								</p>
							</div>
						</div>
						<button
							className="shiny-effect ml-auto min-w-[126px] rounded-[4px] bg-[#1F8268] px-[16px] py-[10px] text-center text-[14px] font-semibold leading-[20px] text-white"
							type="button"
							aria-label="download app button"
							onClick={handleDownloadClick}
						>
							Download Now
						</button>
					</div>
				</div>
				<div className="fixed inset-x-0 bottom-14 z-20 mx-auto hidden w-full max-w-[1280px] md:block">
					<div className="shiny-effect mx-[16px] flex w-full max-w-[327px] space-x-[8px] rounded-[12px] rounded-t-[12px] border border-solid border-[#D3BEF1] bg-[#F1EAFA] p-[12px] shadow-md">
						<div className="space-y-[2px]">
							<div className="h-[66px] w-[66px]">
								<Image
									className="border bg-white object-contain p-2"
									src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/app-qr.png"
									alt="Scan to download"
									height={66}
									width={66}
								/>
							</div>
							<p className="m-0 text-center text-[10px] leading-[16px] text-[#42526E]">
								Scan the QR
							</p>
						</div>
						<div className="flex flex-col items-center space-y-[4px]">
							<div className="h-[21px] w-[1px] bg-[#D3BEF1]" />
							<p className="m-0 text-[12px] leading-[16px] text-[#42526E]">
								Or
							</p>
							<div className="h-[21px] w-[1px] bg-[#D3BEF1]" />
						</div>
						<div className="h-max flex-1 space-y-[8px]">
							<img
								className="-ml-[6px] -mt-[6px]"
								width={104}
								src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
								alt="Get it on google play"
								id="playstore-icon"
							/>

							<div className="flex space-x-[8px]">
								<ApnaLogo size="32" />
								<div className="space-y-[2px]">
									<div className="flex items-center space-x-[4px]">
										<p className="m-0 text-[12px] font-semibold leading-[16px] text-[#172B4D]">
											4.6
										</p>
										<AdsHalfStar />
									</div>
									<p className="m-0 w-full text-nowrap text-[10px] leading-[12px] text-[#42526E]">
										655K reviews
									</p>
								</div>
								<div className="space-y-[2px]">
									<div className="flex items-center space-x-[4px]">
										<p className="m-0 text-[12px] font-semibold leading-[16px] text-[#172B4D]">
											50M+
										</p>
									</div>
									<p className="m-0 w-full text-nowrap text-[10px] leading-[12px] text-[#42526E]">
										Downloads
									</p>
								</div>
							</div>
						</div>
						<button
							type="button"
							onClick={() => setIsOpen(false)}
							aria-label="close web to app nudge"
							className="h-max"
						>
							<CrossIcon16 />
						</button>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default WebToAppNudge;
